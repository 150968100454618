import { TableRow, Typography, TableCell } from '@mui/material';
import React from 'react';
import { NumericFormat } from 'react-number-format';
import MultipleProgressBar from '../../../CustomComponents/MultipleProgressBar';
import OverdueChip from '../../OverdueChip';
import { theme } from '../../../../theme';
import ProgressTooltip from '../Physicians/ProgressTooltip';
import { Box } from '@mui/system';


function CardManagerRow(props) {
    const totalOpportunityValue = props.cardManager.accepted_review_value + props.cardManager.rejected_review_value + props.cardManager.open_review_value;
    const totalReviews = props.cardManager.num_accepted_reviews + props.cardManager.num_rejected_reviews + props.cardManager.num_open_reviews;
    const approvedPercent = Math.round((props.cardManager.accepted_review_value / Math.max(totalOpportunityValue, 1)) * 100);
    const deniedPercent = Math.round((props.cardManager.rejected_review_value / Math.max(totalOpportunityValue, 1)) * 100);
    const unreviewedPercent = Math.round((props.cardManager.open_review_value / Math.max(totalOpportunityValue, 1)) * 100);
    const overduePercent = Math.round((props.cardManager.overdue_review_value / Math.max(totalOpportunityValue, 1)) * 100);
    const unreviewedCountPercent = Math.round((props.cardManager.num_open_reviews / Math.max(totalReviews, 1)) * 100);
    const approvedCountPercent = Math.round((props.cardManager.num_accepted_reviews / Math.max(totalReviews, 1)) * 100);
    const deniedCountPercent = Math.round((props.cardManager.num_rejected_reviews / Math.max(totalReviews, 1)) * 100);
    const overdueCountPercent = Math.round((props.cardManager.num_overdue_reviews / Math.max(totalReviews, 1)) * 100);

    const progressValues = [
        {
            value: approvedPercent,
            color: `${theme.palette.blue.main}`
        },
        {
            value: deniedPercent,
            color: `${theme.palette.background.deniedProgress}`
        },
        {
            value: unreviewedPercent,
            color: `${theme.palette.background.unreviewedProgress}`
        }
    ];

    return (
        <TableRow key={props.cardManager.id} sx={{ height: 'auto' }}>
            <CardManagerContentCell width='14%' textAlign='left'>
                <Typography variant='normal' fontSize='12px' title={`${props.cardManager.last_name}, ${props.cardManager.first_name}`}>
                    {props.cardManager.last_name}, {props.cardManager.first_name}
                </Typography>
            </CardManagerContentCell>
            <CardManagerContentCell width='35%' textAlign='left'>
                <ProgressTooltip 
                    acceptedCount={props.cardManager.num_accepted_reviews} 
                    acceptedValue={props.cardManager.accepted_review_value / 100} 
                    deniedCount={props.cardManager.num_rejected_reviews} 
                    deniedValue={props.cardManager.rejected_review_value / 100} 
                    unreviewedCount={props.cardManager.num_open_reviews} 
                    unreviewedValue={props.cardManager.open_review_value / 100}
                >
                    <Box>
                        <MultipleProgressBar segments={progressValues} label={
                            props.numericFormat === 'currency' ? 
                                `$${Math.round((totalOpportunityValue - props.cardManager.open_review_value) / 100 / 1000)}k / $${Math.round(totalOpportunityValue / 100 / 1000)}k (${approvedPercent + deniedPercent}%)` : 
                                `${totalReviews - props.cardManager.num_open_reviews} / ${totalReviews} Optimizations (${approvedCountPercent + deniedCountPercent}%)`
                        } />
                    </Box>
                </ProgressTooltip>
            </CardManagerContentCell>
            <CardManagerContentCell width='12%' textAlign='left'>
                <Typography variant='normal' fontSize='12px'>
                    { props.numericFormat === 'currency' && 
                        <NumericFormat value={props.cardManager.accepted_review_value / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} suffix={` (${approvedPercent}%)`} decimalScale={0} fixedDecimalScale />
                    }
                    { props.numericFormat === 'count' && 
                        `${props.cardManager.num_accepted_reviews} Optimizations (${approvedCountPercent}%)`
                    }
                </Typography>
            </CardManagerContentCell>
            <CardManagerContentCell width='12%' textAlign='left'>
                <Typography variant='normal' fontSize='12px'>
                    { props.numericFormat === 'currency' &&
                        <NumericFormat value={props.cardManager.rejected_review_value / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} suffix={` (${deniedPercent}%)`} decimalScale={0} fixedDecimalScale />
                    }
                    { props.numericFormat === 'count' &&
                        `${props.cardManager.num_rejected_reviews} Optimizations (${deniedCountPercent}%)`
                    }
                </Typography>
            </CardManagerContentCell>
            <CardManagerContentCell width='12%' textAlign='left'>
                <Typography variant='normal' fontSize='12px'>
                    { props.numericFormat === 'currency' &&
                        <NumericFormat value={props.cardManager.open_review_value / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} suffix={` (${unreviewedPercent}%)`} decimalScale={0} fixedDecimalScale />
                    }
                    { props.numericFormat === 'count' &&
                        `${props.cardManager.num_open_reviews} Optimizations (${unreviewedCountPercent}%)`
                    }
                </Typography>
            </CardManagerContentCell>
            <CardManagerContentCell width='10%' textAlign='right'>
                <OverdueChip 
                    numOverdue={props.cardManager.num_overdue_reviews} 
                    overdueValue={props.cardManager.overdue_review_value} 
                    overduePercentage={overduePercent} 
                    numOverduePercentage={overdueCountPercent}
                    format={props.numericFormat}
                />
            </CardManagerContentCell>
        </TableRow>
    )
}


function CardManagerContentCell(props) {
    return (
        <TableCell sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: props.width, maxWidth: 0, textAlign: props.textAlign }}>
            {props.children}
        </TableCell>
    );
}

export default CardManagerRow;
