import React from 'react';
import { TableHead, TableRow, TableCell, Typography } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import MultipleProgressBar from '../../../CustomComponents/MultipleProgressBar';
import { theme } from '../../../../theme';
import OverdueChip from '../../OverdueChip';
import ProgressTooltip from '../Physicians/ProgressTooltip';
import { Box } from '@mui/system';


function CardManagerFooter(props) {
    const totalOpportunityValue = props.visibleManagers.reduce((acc, manager) => acc + manager.open_review_value + manager.accepted_review_value + manager.rejected_review_value, 0);
    const totalNumRecs = props.visibleManagers.reduce((acc, manager) => acc + manager.num_open_reviews + manager.num_accepted_reviews + manager.num_rejected_reviews, 0);
    const totalApprovedValue = props.visibleManagers.reduce((acc, manager) => acc + manager.accepted_review_value, 0);
    const totalApprovedPercentage = Math.round((totalApprovedValue / totalOpportunityValue) * 100);
    const totalNumApproved = props.visibleManagers.reduce((acc, manager) => acc + manager.num_accepted_reviews, 0);
    const countApprovedPercentage = Math.round((totalNumApproved / totalNumRecs) * 100);
    const totalDeniedValue = props.visibleManagers.reduce((acc, manager) => acc + manager.rejected_review_value, 0);
    const totalDeniedPercentage = Math.round((totalDeniedValue / totalOpportunityValue) * 100);
    const totalNumDenied = props.visibleManagers.reduce((acc, manager) => acc + manager.num_rejected_reviews, 0);
    const countDeniedPercentage = Math.round((totalNumDenied / totalNumRecs) * 100);
    const totalUnreviewedValue = props.visibleManagers.reduce((acc, manager) => acc + manager.open_review_value, 0);
    const totalUnreviewedPercentage = Math.round((totalUnreviewedValue / totalOpportunityValue) * 100);
    const totalNumUnreviewed = props.visibleManagers.reduce((acc, manager) => acc + manager.num_open_reviews, 0);
    const countUnreviewedPercentage = Math.round((totalNumUnreviewed / totalNumRecs) * 100);
    const totalOverdueValue = props.visibleManagers.reduce((acc, manager) => acc + manager.overdue_review_value, 0);
    const totalOverduePercentage = Math.round((totalOverdueValue / totalOpportunityValue) * 100);
    const totalNumOverdue = props.visibleManagers.reduce((acc, manager) => acc + manager.num_overdue_reviews, 0);
    const countOverduePercentage = Math.round((totalNumOverdue / totalNumRecs) * 100);

    return (
        <TableHead sx={{ bottom: 0, left: 0, position: 'sticky', zIndex: 2 }}>
            <TableRow sx={{ textOverflow: 'ellipsis', overflow: 'hidden', color: 'black', height: '60px', borderTop: '1px solid #DDD'}}>
                <TableCell sx={{ borderBottom: 'none' }}>
                    <Typography variant='heavy' fontSize='12px'>
                        <strong>Total ({props.visibleManagers.length}/{props.totalManagers})</strong>
                    </Typography>
                </TableCell>
                <TableCell sx={{ borderBottom: 'none' }}>
                    <ProgressTooltip 
                        acceptedCount={totalNumApproved} 
                        acceptedValue={totalApprovedValue} 
                        deniedCount={totalNumDenied} 
                        deniedValue={totalDeniedValue} 
                        unreviewedCount={totalNumUnreviewed} 
                        unreviewedValue={totalUnreviewedValue}
                    >
                        <Box>
                            <MultipleProgressBar segments={[
                                {
                                    value: totalApprovedPercentage,
                                    color: `${theme.palette.blue.main}`
                                },
                                {
                                    value: totalDeniedPercentage,
                                    color: `${theme.palette.background.deniedProgress}`
                                },
                                {
                                    value: totalUnreviewedPercentage,
                                    color: `${theme.palette.background.unreviewedProgress}`
                                }
                            ]} label={props.numericFormat === 'currency' ? 
                                <strong>{`$${Math.round((totalOpportunityValue - totalUnreviewedValue) / 100 / 1000)}k / $${Math.round(totalOpportunityValue / 100 / 1000)}k (${100 - totalUnreviewedPercentage}%)`}</strong> : 
                                <strong>{`${totalNumRecs - totalNumUnreviewed} / ${totalNumRecs} Optimizations (${100 - countUnreviewedPercentage}%)`}</strong>
                            } />
                        </Box>
                    </ProgressTooltip>
                </TableCell>
                <TableCell sx={{ borderBottom: 'none' }}>
                    <Typography variant='heavy' fontSize='12px'>
                        <strong>
                            { props.numericFormat === 'currency' &&
                                <NumericFormat value={totalApprovedValue / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} suffix={` (${totalApprovedPercentage})%`} decimalScale={0} fixedDecimalScale />
                            }
                            { props.numericFormat === 'count' &&
                                `${totalNumApproved} Optimizations (${countApprovedPercentage}%)`
                            }
                        </strong>
                    </Typography>
                </TableCell>
                <TableCell sx={{ borderBottom: 'none' }}>
                    <Typography variant='heavy' fontSize='12px'>
                        <strong>
                            { props.numericFormat === 'currency' &&
                                <NumericFormat value={totalDeniedValue / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} suffix={` (${totalDeniedPercentage})%`} decimalScale={0} fixedDecimalScale />
                            }
                            { props.numericFormat === 'count' &&
                                `${totalNumDenied} Optimizations (${countDeniedPercentage}%)`
                            }
                        </strong>
                    </Typography>
                </TableCell>
                <TableCell sx={{ borderBottom: 'none' }}>
                    <Typography variant='heavy' fontSize='12px'>
                        <strong>
                            { props.numericFormat === 'currency' &&
                                <NumericFormat value={totalUnreviewedValue / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} suffix={` (${totalUnreviewedPercentage})%`} decimalScale={0} fixedDecimalScale />
                            }
                            { props.numericFormat === 'count' &&
                                `${totalNumUnreviewed} Optimizations (${countUnreviewedPercentage}%)`
                            }
                        </strong>
                    </Typography>
                </TableCell>
                <TableCell sx={{ textAlign: 'right', borderBottom: 'none' }}>
                    <OverdueChip
                        overdueValue={totalOverdueValue}
                        numOverdue={totalNumOverdue}
                        overduePercentage={totalOverduePercentage}
                        numOverduePercentage={countOverduePercentage}
                        format={props.numericFormat}
                    />
                </TableCell>
            </TableRow>
        </TableHead>
    )
}

export default CardManagerFooter;
