import React from 'react';
import { TableFooter, TableRow, TableCell, Typography, TableHead } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import OverdueChip from '../OverdueChip';
import MultipleProgressBar from '../../CustomComponents/MultipleProgressBar';
import { theme } from '../../../theme';


function OverviewFooter(props) {
    const totalCapturedValue = props.visibleCampaigns.reduce((acc, campaign) => acc + campaign.captured_value, 0);
    const totalOpportunityValue = props.visibleCampaigns.reduce((acc, campaign) => acc + campaign.opportunity_value, 0);
    const totalEngagedPhysicians = props.visibleCampaigns.reduce((acc, campaign) => acc + campaign.num_engaged_physicians, 0);
    const totalIncludedPhysicians = props.visibleCampaigns.reduce((acc, campaign) => acc + campaign.num_included_physicians, 0);
    const totalStaffReviewedValue = props.visibleCampaigns.reduce((acc, campaign) => acc + campaign.staff_reviewed_value, 0);
    const totalStaffOpportunityValue = props.visibleCampaigns.reduce((acc, campaign) => acc + campaign.staff_opportunity_value, 0);
    const totalOverdueReviews = props.visibleCampaigns.reduce((acc, campaign) => acc + campaign.num_overdue_reviews, 0);
    const totalOverdueValue = props.visibleCampaigns.reduce((acc, campaign) => acc + campaign.value_of_overdue_reviews, 0);

    return ( 
        <TableHead sx={{ bottom: 0, left: 0, position: 'sticky', zIndex: 2 }}>
            <TableRow sx={{ textOverflow: 'ellipsis', overflow: 'hidden', color: 'black', height: '60px', borderTop: '1px solid #DDD' }}>
                <TableCell sx={{ borderBottom: 'none' }}>
                    <Typography variant='heavy' fontSize='12px'>
                        <strong>Total ({props.visibleCampaigns.length}/{props.totalCampaigns})</strong>
                    </Typography>
                </TableCell>
                <TableCell sx={{ borderBottom: 'none' }}>-</TableCell>
                <TableCell sx={{ borderBottom: 'none' }}>-</TableCell>
                <TableCell sx={{ borderBottom: 'none' }}>-</TableCell>
                <TableCell sx={{ textAlign: 'right', borderBottom: 'none' }}>
                    <Typography variant='heavy' fontSize='12px'>
                        <strong>
                            <NumericFormat value={totalCapturedValue/ 100} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={0} fixedDecimalScale />
                        </strong> {' / '}  
                        <NumericFormat value={totalOpportunityValue / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={0} fixedDecimalScale />
                    </Typography>
                </TableCell>
                <TableCell sx={{ borderBottom: 'none' }}>
                    <MultipleProgressBar segments={[
                        {
                            value: (totalEngagedPhysicians / Math.max(totalIncludedPhysicians, 1)) * 100,
                            color: `${theme.palette.blue.main}`
                        },
                        {
                            value: (100 - (totalEngagedPhysicians / Math.max(totalIncludedPhysicians, 1)) * 100),
                            color: `${theme.palette.background.unreviewedProgress}`
                        }
                    ]} label={`${Math.round((totalEngagedPhysicians / Math.max(totalIncludedPhysicians, 1)) * 100)}%`} />
                </TableCell>
                <TableCell sx={{ borderBottom: 'none' }}>
                    <MultipleProgressBar segments={[
                        {
                            value: (totalStaffReviewedValue / Math.max(totalStaffOpportunityValue, 1)) * 100,
                            color: `${theme.palette.blue.main}`
                        },
                        {
                            value: (100 - (totalStaffReviewedValue / Math.max(totalStaffOpportunityValue, 1)) * 100),
                            color: `${theme.palette.background.unreviewedProgress}`
                        }
                    ]} label={`${Math.round((totalStaffReviewedValue / Math.max(totalStaffOpportunityValue, 1)) * 100)}%`} />
                </TableCell>
                <TableCell sx={{ textAlign: 'right', borderBottom: 'none' }}>
                    <OverdueChip numOverdue={totalOverdueReviews} overdueValue={totalOverdueValue} />
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

export default OverviewFooter;
