import { TableRow, TableCell, Typography } from '@mui/material';
import React from 'react';
import { NumericFormat } from 'react-number-format';
import MultipleProgressBar from '../../../CustomComponents/MultipleProgressBar';
import { theme } from '../../../../theme';
import { Box } from '@mui/system';
import ProgressTooltip from './ProgressTooltip';

function PhysicianRow(props) {
    const recommendationSummary = props.physician.recommendation_summary;
    const opportunityValue = recommendationSummary.total_value / 100;
    const approvedValue = recommendationSummary.accepted_value / 100;
    const approvedPercent = Math.round((approvedValue / opportunityValue) * 100);
    const deniedValue = recommendationSummary.rejected_value / 100;
    const deniedPercent = Math.round((deniedValue / opportunityValue) * 100);
    const unreviewedValue = recommendationSummary.open_value / 100;
    const unreviewedPercent = Math.round((unreviewedValue / opportunityValue) * 100);
    const unreviewedCountPercent = Math.round((recommendationSummary.num_open / recommendationSummary.total_recs) * 100);
    const approvedCountPercent = Math.round((recommendationSummary.num_accepted / recommendationSummary.total_recs) * 100);
    const deniedCountPercent = Math.round((recommendationSummary.num_rejected / recommendationSummary.total_recs) * 100);

    const progressValues = [
        {
            value: approvedPercent,
            color: `${theme.palette.blue.main}`
        },
        {
            value: deniedPercent,
            color: `${theme.palette.background.deniedProgress}`
        },
        {
            value: unreviewedPercent,
            color: `${theme.palette.background.unreviewedProgress}`
        }
    ];

    return (
        <TableRow key={props.physician.id} sx={{ height: 'auto' }}>
            <PhysicianContentCell width='14%' textAlign='left'>
                <Typography variant='normal' fontSize='12px' title={`Dr. ${props.physician.last_name}, ${props.physician.first_name}`}>
                    Dr. {props.physician.last_name}, {props.physician.first_name}
                </Typography>
            </PhysicianContentCell>
            <PhysicianContentCell width='35%' textAlign='left'>
                <ProgressTooltip 
                    acceptedCount={recommendationSummary.num_accepted} 
                    acceptedValue={recommendationSummary.accepted_value / 100} 
                    deniedCount={recommendationSummary.num_rejected} 
                    deniedValue={recommendationSummary.rejected_value / 100} 
                    unreviewedCount={recommendationSummary.num_open} 
                    unreviewedValue={recommendationSummary.open_value / 100}
                >
                    <Box>
                        <MultipleProgressBar segments={progressValues} label={
                            props.numericFormat === 'currency' ? 
                                `$${Math.round((opportunityValue - unreviewedValue) / 1000)}k / $${Math.round(opportunityValue / 1000)}k (${approvedPercent + deniedPercent}%)` : 
                                `${recommendationSummary.total_recs - recommendationSummary.num_open} / ${recommendationSummary.total_recs} Optimizations (${approvedCountPercent + deniedCountPercent}%)`
                        } />
                    </Box>
                </ProgressTooltip>
            </PhysicianContentCell>
            <PhysicianContentCell width='12%' textAlign='left'>
                <Typography variant='normal' fontSize='12px'>
                    { props.numericFormat === 'currency' && 
                        <NumericFormat value={opportunityValue} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={0} fixedDecimalScale />
                    }
                    { props.numericFormat === 'count' && 
                        `${recommendationSummary.total_recs} Optimizations`
                    }
                </Typography>
            </PhysicianContentCell>
            <PhysicianContentCell width='12%' textAlign='left'>
                <Typography variant='normal' fontSize='12px'>
                    { props.numericFormat === 'currency' &&
                        <NumericFormat value={approvedValue} displayType={'text'} thousandSeparator={true} prefix={'$'} suffix={` (${approvedPercent}%)`} decimalScale={0} fixedDecimalScale />
                    }
                    { props.numericFormat === 'count' &&
                        `${recommendationSummary.num_accepted} Optimizations (${Math.round((recommendationSummary.num_accepted / recommendationSummary.total_recs) * 100)}%)`
                    }
                </Typography>
            </PhysicianContentCell>
            <PhysicianContentCell width='12%' textAlign='left'>
                <Typography variant='normal' fontSize='12px'>
                    { props.numericFormat === 'currency' &&
                        <NumericFormat value={deniedValue} displayType={'text'} thousandSeparator={true} prefix={'$'} suffix={` (${deniedPercent}%)`} decimalScale={0} fixedDecimalScale />
                    }
                    { props.numericFormat === 'count' &&
                        `${recommendationSummary.num_rejected} Optimizations (${Math.round((recommendationSummary.num_rejected / recommendationSummary.total_recs) * 100)}%)`
                    }
                </Typography>
            </PhysicianContentCell>
            <PhysicianContentCell width='15%' textAlign='left'>
                <Typography variant='normal' fontSize='12px'>
                    { props.numericFormat === 'currency' &&
                        <NumericFormat value={unreviewedValue} displayType={'text'} thousandSeparator={true} prefix={'$'} suffix={` (${unreviewedPercent}%)`} decimalScale={0} fixedDecimalScale />
                    }
                    { props.numericFormat === 'count' &&
                        `${recommendationSummary.num_open} Optimizations (${unreviewedCountPercent}%)`
                    }
                </Typography>
            </PhysicianContentCell>
        </TableRow>
    );
}

function PhysicianContentCell(props) {
    return (
        <TableCell sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: props.width, maxWidth: 0, textAlign: props.textAlign }}>
            {props.children}
        </TableCell>
    );
}

export default PhysicianRow;
