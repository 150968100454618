import { Typography, CircularProgress } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useState, useEffect } from 'react';
import { useGetOrganizationCampaignsQuery } from '../../api/campaignMonitoringSlice';
import FilterManager from './FilterManager';
import CampaignOverviewTable from './CampaignOverview/CampaignOverviewTable';
import CampaignDetails from './CampaignDetails/CampaignDetails';
import { useContext } from 'react';
import { UserContext } from '../Hooks/useUser';
import { useLogUserActionMutation } from '../../api/apiSlice';


function CampaignMonitoring() {
    const { user } = useContext(UserContext);
    const { data: campaigns = [], isLoading, isSuccess } = useGetOrganizationCampaignsQuery(user.hospital.organization_id);
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [appliedFilters, setAppliedFilters] = useState({});
    const [logUserAction] = useLogUserActionMutation();

    useEffect(() => {
        if (isSuccess) {
            logUserAction({ action: 'loaded_campaign_monitoring_campaigns', data: { organization: user.hospital.organization_id } });
        }
    }, [isSuccess]);
    
    return (
        <Stack px={4} pb={4} spacing={4} sx={{ height: '100%' }}>
            <Stack sx={{ height: '60%'}}>
                <Stack direction='row' alignItems='center' spacing={1} py={1}>
                    <Typography variant='heavy' fontSize='18px'><strong>Campaign Overview</strong></Typography>
                    { isSuccess && 
                        <FilterManager campaigns={campaigns} appliedFilters={appliedFilters} setAppliedFilters={setAppliedFilters} />
                    }
                </Stack>
                { isLoading && 
                    <Stack width="100%" height="100%" justifySelf="center" alignItems="center" spacing={2} pt={5}>
                        <Typography variant="h4">Loading Campaign Overview...</Typography>
                        <CircularProgress />
                    </Stack>
                }
                { isSuccess && 
                    <CampaignOverviewTable 
                        campaigns={campaigns} 
                        appliedFilters={appliedFilters} 
                        selectedCampaign={selectedCampaign} 
                        setSelectedCampaign={setSelectedCampaign} 
                    />
                }
            </Stack>
            { selectedCampaign && 
                <Stack sx={{ height: '60%' }}>
                    <CampaignDetails campaign={selectedCampaign} setSelectedCampaign={setSelectedCampaign} />
                </Stack>
            }
        </Stack>
    );
}

export default CampaignMonitoring;
