import { Stack } from '@mui/system';
import React, { useState } from 'react';
import { CircularProgress, IconButton, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useGetPhysiciansInCampaignQuery, useGetCardManagersInCampaignQuery } from '../../../api/campaignMonitoringSlice';
import PhysiciansTable from './Physicians/PhysiciansTable';
import CardManagersTable from './CardManagers/CardManagersTable';
import { format, parseISO } from 'date-fns';
import { CloseRounded } from '@mui/icons-material';
import { useLogUserActionMutation } from '../../../api/apiSlice';


function CampaignDetails(props) {
    const { data: physicians = [], isSuccess: hasLoadedPhysicians, isLoading: isLoadingPhysicians } = useGetPhysiciansInCampaignQuery(props.campaign.id);
    const { data: cardManagers = [], isSuccess: hasLoadedCardManagers, isLoading: isLoadingCardManagers } = useGetCardManagersInCampaignQuery(props.campaign.id);
    const [logUserAction] = useLogUserActionMutation();
    const [usersToShow, setUsersToShow] = useState('physicians');
    const [numericFormat, setNumericFormat] = useState('currency');
    const campaignStatus = props.campaign.status === 'active' || props.campaign.status === 'staff_review' ? 'Active' : props.campaign.status;

    function handleToggleUsers(userType) {
        setUsersToShow(userType);
        logUserAction({ action: 'toggled_campaign_monitoring_users', data: { 
            user_type: userType,
            campaign: props.campaign,
        } });
    }

    function handleToggleNumericFormat(format) {
        setNumericFormat(format);
        logUserAction({ action: 'toggled_campaign_monitoring_numeric_format', data: { 
            numeric_format: format,
            campaign: props.campaign,
        } });
    }

    return (
        <Stack sx={{ height: '100%' }}>
            <Stack direction='row' alignItems='center' spacing={1}>
                <Typography variant='heavy' fontSize='18px'><strong>Campaign Details: </strong>{props.campaign.location}, {props.campaign.specialties.join(', ')}, {format(parseISO(props.campaign.start_date), 'MM/dd/yy')}, {campaignStatus}</Typography>
                <IconButton onClick={() => props.setSelectedCampaign(null)}><CloseRounded /></IconButton>
            </Stack>
            { (isLoadingPhysicians || isLoadingCardManagers) &&
                <Stack width="100%" height="100%" justifySelf="center" alignItems="center" spacing={2} pt={5}>
                    <Typography variant="h4">Loading Campaign Details...</Typography>
                    <CircularProgress />
                </Stack>
            }
            { hasLoadedPhysicians && hasLoadedCardManagers &&
                <Stack spacing={1} maxHeight='100%' pb={4}>
                    <Stack direction='row' alignItems='center' justifyContent='space-between'>
                        <ToggleButtonGroup size='small' value={usersToShow} exclusive onChange={(e, value) => handleToggleUsers(value)} color='primary'>
                            <ToggleButton value='physicians'>Physicians</ToggleButton>
                            <ToggleButton value='card_managers'>Card Managers</ToggleButton>
                        </ToggleButtonGroup>
                        <ToggleButtonGroup size='small' value={numericFormat} exclusive onChange={(e, value) => handleToggleNumericFormat(value)} color='primary'>
                            <ToggleButton value='currency'>$</ToggleButton>
                            <ToggleButton value='count'>#</ToggleButton>
                        </ToggleButtonGroup>
                    </Stack>
                    { usersToShow === 'physicians' &&
                        <PhysiciansTable physicians={physicians} appliedFilters={{}} numericFormat={numericFormat} />
                    }
                    { usersToShow === 'card_managers' &&
                        <CardManagersTable cardManagers={cardManagers} appliedFilters={{}} numericFormat={numericFormat} />
                    }
                </Stack>
            }
        </Stack>
    );
}

export default CampaignDetails;
